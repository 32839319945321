import styled from 'styled-components';
import { ButtonGroup } from 'react-bootstrap';

export const ToolbarGap = styled.div`
  height: 30px;
  position: sticky;
  background-color: var(--secondary-background-color);
  top: 0;
  z-index: 1000;
  margin: 0 -20px -10px;
`;

export const Container = styled.div`
  background-color: var(--background-color);
  position: sticky;
  top: 20px;
  left: 0;
  z-index: 1000;
  padding: 20px 10px 20px 5px;
  margin: 0 0 20px;
  border-radius: var(--devo-border-radius);
  box-shadow: var(--devo-shadow);

  .filters {
    transition: max-height 0.1s, opacity 0.1s, padding-top 0.1s;
  }

  .filters.hidden {
    max-height: 0;
    opacity: 0;
    padding: 0;
    overflow: hidden;
  }

  .filters.showing {
    max-height: 51px;
    opacity: 1;
    padding: 15px 0 0 5px
  }

  @media (max-width: 768px) {
    margin: 0px -10px 15px;
    border-radius: 0;
    box-shadow: none;
  }
`;

export const Wrapper = styled.div`
  background-color: var(--background-color);
  display: flex;
  align-items: center;

  .results {
    margin-left: 10px;
    color: var(--secondary-label-color);
  }

  .loader {
    margin: 0 10px;
  }

  @media (max-width: 1000px) {
    span.results {
      display: none;
    }
  }

  @media (max-width: 1200px) {
    div.resultsPerPage {
      display: none;
    }
  }
`;

export const Group = styled(ButtonGroup)`
  box-shadow: var(--devo-shadow);
`;

export const Button = styled.button`
  padding: 5px 10px;
  color: var(--primary-label-color);
  background-color: var(--background-color);
  border: var(--devo-border);
  border-right: none;

  .red {
    color: var(--devo-red);
  }

  &:first-child {
    border-radius: var(--devo-border-radius) 0 0 var(--devo-border-radius);
  }

  &:last-child {
    border-right: var(--devo-border);
    border-radius: 0 var(--devo-border-radius) var(--devo-border-radius) 0;
  }

  &:only-child {
    border-radius: var(--devo-border-radius);
  }

  &:active {
    color: var(--primary-label-color);
    background-color: var(--secondary-background-color);
  }

  &:focus {
    outline: none;
  }

  &.filter {
    background-color: var(--devo-red);
    color: #fff;
    border-color: var(--devo-red);
  }
`;

export const FilterButton = styled.button`
  padding: 5px 10px;
  color: #fff;
  background-color: var(--devo-red);
  border: none;

  &:first-child {
    border-radius: var(--devo-border-radius) 0 0 var(--devo-border-radius);
  }

  &:last-child {
    border-radius: 0 var(--devo-border-radius) var(--devo-border-radius) 0;
  }

  &:only-child {
    border-radius: var(--devo-border-radius);
  }

  &:hover {
    background-color: var(--devo-dark-red);
  }

  &:focus {
    outline: none;
  }
`;

export const Spacer = styled.div`
  flex-grow: 2;
`;

export const DropdownButton = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;

  &.resultsPerPage {
    margin: 0 30px;
  }

  .dropdown {
    margin: 0 10px;
  }

  .dropdown .btn {
    padding: 5px 10px;
    color: var(--primary-label-color);
    background-color: var(--background-color);
    border: var(--devo-border);
    border-radius: var(--devo-border-radius);
    box-shadow: var(--devo-shadow);
  }

  .dropdown .btn:focus, .btn:active {
    background-color: var(--background-color) !important;
    color: var(--primary-label-color) !important;
    border: var(--devo-border);
  }
`;

export const Search = styled.div`
  padding: 5px 0;
  border: var(--devo-border);
  box-shadow: var(--devo-shadow);
  border-radius: var(--devo-border-radius);

  svg {
    margin: 0 5px 0 10px;
  }
`;

export const SearchBar = styled.input`
  border: none;
  height: 100%;
  margin-right: 5px;

  &:focus {
    outline: none;
  }

  @media (min-width: 1300px) {
    min-width: 300px;
  }
`;